export const avatarStyle = {
  width: {
    xs: "142px",
    sm: "80%",
    md: "75%",
    lg: "152px",
    xl: "186px",
  },
  height: {
    xs: "142px",
    sm: "26vw",
    md: "15vw",
    lg: "152px",
    xl: "186px",
  },
};
