export const dividerStyles = {
  height: "0.1px",
  bgcolor: "#999",
};

export const clearBtn = {
  color: "#5A5A5A",
  fontSize: "13px",
  height: "24px",
};
